import React, { useState, useEffect } from 'react'
import {
  Text,
  Heading,
  Container,
  Stack,
  Divider,
  ListItem,
  OrderedList,
  // UnorderedList,
  Button,
  Link,
} from '@chakra-ui/react'
import faker from 'faker'
import { graphql } from 'gatsby'
import BaseLayout from '../layout/base'
import {
  Heading1,
  Heading2,
  // Heading3,
  // Heading4,
  // Heading5,
  // Heading6,
  Paragraph,
} from '../components/ContentWithStyles/TransformToChakra'
import SEO from '../components/SEO/PageSEO'

const Page = ({ location, data }) => {
  const [cardNumber, setCardNumber] = useState('3053-034193-8241')
  const [cardCCV, setCardCCV] = useState('361')

  const generateCreditCard = () => {
    setCardNumber(faker.finance.creditCardNumber())
    setCardCCV(faker.finance.creditCardCVV())
  }

  useEffect(() => {
    generateCreditCard()
  }, [])

  return (
    <BaseLayout>
      <SEO
        title="Generador de tarjetas de crédito aleatorias"
        titleSeo="Generador de tarjetas de crédito aleatorias"
        description="Herramienta para generar tarjetas de crédito al azar con número y CCV."
        image={data.file.childImageSharp.gatsbyImageData.images.fallback.src}
        // lang="es"
        datePublished="2021-05-11T12:29:33-03:00"
        dateModified="2021-05-11T12:29:33-03:00"
        // breadcrumbs={[{ name: 'Vegetales', url: '/vegetales/' }]}
        permalink={location.pathname}
        // breadcrumbList={[]}
        // noindex={false}
      />
      <Container maxW="5xl">
        <Stack
          textAlign="center"
          align="center"
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 28 }}
        >
          <Heading
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
            lineHeight="110%"
            as="span"
          >
            <Text as="span" color="orange.400">
              {cardNumber}
            </Text>
          </Heading>
          <Heading
            fontWeight={600}
            fontSize={{ base: 'xl', sm: '2xl', md: '4xl' }}
            lineHeight="110%"
            as="span"
          >
            {'CCV '}
            <Text as="span" color="orange.400">
              {cardCCV}
            </Text>
          </Heading>
          <Heading as="h2" color="gray.500" size="md" fontWeight={500}>
            Generador de tarjetas de crédito aleatorias con CCV
          </Heading>

          <Stack spacing={2} direction="row">
            <Button
              rounded="full"
              px={6}
              colorScheme="orange"
              bg="orange.400"
              _hover={{ bg: 'orange.500' }}
              onClick={() => {
                generateCreditCard()
              }}
            >
              Generar
            </Button>
          </Stack>
        </Stack>
      </Container>
      <Divider />
      <Container maxW="5xl" my={10}>
        <Heading1>
          Generador de datos de tarjeta de cr&eacute;dito aleatorios:
          n&uacute;meros y CVV, CCV
        </Heading1>
        <Paragraph>
          Este generador de tarjetas de cr&eacute;dito al azar te permite
          obtener todos los{' '}
          <strong>
            datos ficticios necesarios para completar una tarjeta de
            cr&eacute;dito o d&eacute;bito falsa
          </strong>
          , que no funciona, no es real y no se puede utilizar en transacciones,
          pero{' '}
          <strong>
            puede servir para rellenar formularios y hacer pruebas
          </strong>{' '}
          en sitios o aplicaciones que requieran los datos de tarjeta de
          cr&eacute;dito en el formato adecuado.
        </Paragraph>
        <Paragraph>
          <strong>
            Los datos de la tarjeta de cr&eacute;dito aleatoria que se genera
            con cada uso son completamente aleatorios e inventados en el momento
            de uso
          </strong>
          , no provienen de ninguna base de datos de tarjetas de cr&eacute;dito
          o d&eacute;bito reales y no es posible utilizarlos en ning&uacute;n
          sitio para hacer compras o pagos, simplemente sirven a modo ejemplo,
          para realizar pruebas que requieran el formato adecuado pero que no
          validen la tarjeta de cr&eacute;dito para su uso comercial.
        </Paragraph>
        <Paragraph>
          Este generador de n&uacute;meros de tarjeta de cr&eacute;dito con CCV
          tiene como prop&oacute;sito principal el brindar datos aleatorios en
          el formato correcto para su uso leg&iacute;timo en pruebas (test),
          dise&ntilde;os de interfaz de usuario, procesos de desarrollo,
          dise&ntilde;o de prototipos realistas, relatos e historias ficticias,
          adorno o decoraci&oacute;n, ejemplo educativo, o cualquier uso similar
          que no implique el intentar hacer alguna transacci&oacute;n comercial
          con dichos datos. En resumen,{' '}
          <strong>
            este generador de tarjetas de cr&eacute;dito falsas sirve a
            prop&oacute;sitos educativos, de prueba y de ejemplo
          </strong>
          .
        </Paragraph>
        <Paragraph>
          Los datos son &uacute;nicos y siempre respetan el formato necesario
          para cada campo, no se trata de una base de datos con
          informaci&oacute;n previamente generada, sino que con cada uso se
          ejecuta el algoritmo de generaci&oacute;n aleatoria de datos de
          tarjetas de cr&eacute;dito con CVV para asegurar que cada resultado
          sea &uacute;nico y realmente aleatorio, sin seguir ning&uacute;n orden
          o repetir las mismas combinaciones.
        </Paragraph>
        <Heading2>
          Datos falsos para tarjetas de cr&eacute;dito generados al azar
        </Heading2>
        <Paragraph>
          El generador de tarjetas de cr&eacute;ditos con datos ficticios es
          bastante simple y efectivo en su &uacute;nica tarea: ser capaz de
          proveer r&aacute;pidamente un conjunto de datos en el formato adecuado
          para completar todos los campos requeridos por una tarjeta de
          cr&eacute;dito o d&eacute;bito. No hay mucha profundidad ni
          complejidad en el proceso ni en los datos resultantes, simplemente se
          deben seguir unas reglas de formato b&aacute;sicas y utilizar un
          algoritmo de generaci&oacute;n de datos al azar.
        </Paragraph>
        <Paragraph>
          Dicho esto,{' '}
          <Link
            color="orange.400"
            href="https://www.creditcardinsider.com/learn/anatomy-of-a-credit-card/"
            target="_blank"
            rel="noreferrer noopener"
          >
            los datos de una tarjeta de cr&eacute;dito o d&eacute;bito son
            est&aacute;ndar y tienen un significado
          </Link>{' '}
          bastante simple de entender, pero de todas formas vamos a repasar
          cu&aacute;l es la informaci&oacute;n generada con cada tarjeta y
          cu&aacute;l es su uso o motivo.
        </Paragraph>
        <Paragraph>
          Datos que provee el generador de tarjeta de cr&eacute;dito y
          d&eacute;bito falsas:
        </Paragraph>
        <OrderedList>
          <ListItem>
            <strong>N&uacute;mero:</strong> la parte quiz&aacute; m&aacute;s
            importante, el n&uacute;mero &uacute;nico que permite identificar de
            forma un&iacute;voca a la tarjeta espec&iacute;fica. Es el
            n&uacute;mero de identidad de una tarjeta en concreto, y sigue{' '}
            <Link
              color="orange.400"
              href="https://www.iso.org/standard/66011.html"
              target="_blank"
              rel="noreferrer noopener"
            >
              el est&aacute;ndar ISO/IEC 7812-1:2015
            </Link>{' '}
            que define con precisi&oacute;n el formato al que deben adherirse
            los n&uacute;meros de tarjeta de cr&eacute;dito. Los n&uacute;meros
            de tarjeta de cr&eacute;dito contienen en realidad mucha
            informaci&oacute;n &uacute;til acerca de la misma, pero que no son
            realmente necesarios para el consumidor que las usa, por lo que si
            tienes curiosidad puedes leer sobre ellos, pero no
            necesitar&aacute;s usarlos realmente.
          </ListItem>
          <ListItem>
            <strong>C&oacute;digo de seguridad (CVV):</strong> tal y como su
            nombre lo indica, este n&uacute;mero de tres caracteres existe por
            motivos de seguridad, m&aacute;s espec&iacute;ficamente para
            prevenir el uso fraudulento de la tarjeta en cuesti&oacute;n. No son
            realmente una contrase&ntilde;a, aunque se asemejan a la misma; con
            el n&uacute;mero de tarjeta, el c&oacute;digo de seguridad y la
            fecha de expiraci&oacute;n, alguien podr&iacute;a cometer fraude con
            una tarjeta espec&iacute;fica, por lo que es de suma importancia no
            revelar p&uacute;blicamente ni el CVV ni el resto de datos de la
            tarjeta.
          </ListItem>
          <ListItem>
            <strong>
              Fecha de vencimiento/expiraci&oacute;n (mes y a&ntilde;o):
            </strong>{' '}
            m&aacute;s all&aacute; de establecer hasta cu&aacute;ndo
            podr&aacute; ser utilizada la misma antes de pedir una
            renovaci&oacute;n, tambi&eacute;n resulta un dato importante al
            momento de hacer transacciones online, ya que siempre ser&aacute;
            requerido para que acepten nuestra tarjeta. No es necesario esperar
            hasta dicha fecha para pedir un cambio o renovaci&oacute;n de la
            tarjeta, as&iacute; como tampoco hay que preocuparse por el estado
            de la cuenta bancaria que respalda la misma, dado que la fecha de
            expiraci&oacute;n aplica &uacute;nicamente a la tarjeta de
            cr&eacute;dito o d&eacute;bito y no a la cuenta bancaria.
          </ListItem>
          <ListItem>
            <strong>Entidad emisora:</strong> hace referencia a la entidad
            bancaria que emiti&oacute; la tarjeta, y en la cual est&aacute; la
            cuenta bancaria del cliente que est&aacute; ligada a los fondos
            utilizados por dicha tarjeta de cr&eacute;dito. Bastante simple y
            directo, este dato no tiene mucho misterio.
          </ListItem>
          <ListItem>
            <strong>Red de la tarjeta:</strong> esta es la red que da soporte al
            funcionamiento de la misma, y es diferente a la entidad emisora de
            la tarjeta. La red de la tarjeta de cr&eacute;dito es el sistema que
            est&aacute; detr&aacute;s de la misma y mediante el cual se procesan
            las transacciones. Las redes de tarjetas de cr&eacute;dito
            m&aacute;s populares son: Visa, MasterCard, American Express,
            Discover, entre otras.
          </ListItem>
          <ListItem>
            <strong>Nombre y apellido del propietario:</strong> no hay mucho que
            explicar respecto a este campo, se trata del nombre y apellido de la
            persona responsable de la tarjeta de cr&eacute;dito y la cuenta
            bancaria que respalda a la misma.
          </ListItem>
        </OrderedList>
        <Paragraph>
          Todos estos campos pueden ser completados utilizando el generador de
          tarjetas de cr&eacute;dito fake de esta misma p&aacute;gina, con datos
          v&aacute;lidos en t&eacute;rminos de formato, pero inv&aacute;lidos
          respecto a su uso a nivel comercial y para transacciones y
          suscripciones.
        </Paragraph>
        <Paragraph>
          La mayor&iacute;a de los prototipos, dise&ntilde;os y formularios
          simplemente requerir&aacute;n de la introducci&oacute;n de
          informaci&oacute;n en tres (o cuatro) campos: n&uacute;mero de
          tarjeta, c&oacute;digo de seguridad (CVV), fecha de expiraci&oacute;n
          y el a veces opcional nombre del propietario.
        </Paragraph>
        <Heading2>Has pruebas y desarrolla prototipos en segundos</Heading2>
        <Paragraph>
          Con este generador de tarjetas de cr&eacute;ditos falsas con datos
          completos podr&aacute;s poner a prueba tus interfaces de usuarios,
          rellenar tus bases de datos en entornos de desarrollo, realizar test
          sobre prototipos de plataformas de compras y suscripciones, dar
          ejemplos a modo educativo, y mucho m&aacute;s.
        </Paragraph>
        <Paragraph>
          Si intentas generar datos falsos de tarjetas de cr&eacute;dito al azar
          para luego utilizarlos en sistemas reales que est&aacute;n disponibles
          de cara al p&uacute;blico y cuentan con controles de seguridad, los
          datos no ser&aacute;n aceptados y la tarjeta ser&aacute; rechazada,
          dado que son completamente aleatorios y no pasan por ning&uacute;n
          filtro de seguridad, solo sirven para hacer pruebas y rellenar
          formularios a modo ejemplo.
        </Paragraph>
        <Paragraph>
          Al poder generar miles de tarjetas de cr&eacute;dito ficticias en
          cuesti&oacute;n de segundos, podr&aacute;s completar
          r&aacute;pidamente cualquier tarea que requiera de la prueba con datos
          falsos, pero en el formato correcto. Esto agilizar&aacute; mucho el
          test de interfaces, prototipos, plataformas en desarrollo, ejemplos,
          etc. y te permitir&aacute; centrar el uso de tu tiempo en la parte
          m&aacute;s importante y no perderlo en escribir manualmente la
          informaci&oacute;n correspondiente a muchas tarjetas de cr&eacute;dito
          falsas.
        </Paragraph>
      </Container>
    </BaseLayout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    file(relativePath: { regex: "/extreme-5497194_1920-photopea.png/" }) {
      childImageSharp {
        gatsbyImageData(quality: 40, width: 800)
      }
    }
  }
`
